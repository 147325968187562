<template>
  <div class="p-2 cursor-pointer">
    <input
      ref="uploads"
      @change="previewFiles"
      class="hidden"
      type="file"
      name=""
      multiple
      id=""
    />
    <div
      class="flex text-left flex-col-reverse cursor-pointer"
      @click="$refs.uploads.click()"
    >
      <div
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        class="justify-between items-center w-auto px-1 py-6 border-2 border-dashed rounded-md cursor-pointer border-input flex flex-col focus:border-blue-500"
      >
        <img
          style="width: 250px"
          class="cursor-pointer"
          src="../assets/img/file.jpg"
          alt=""
        />
        <div
          v-if="files.length === 0"
          class="h-full rounded-md flex justify-center items-center"
        >
          Click to upload file
        </div>
        <div v-else>
          <div class="flex" v-for="(file, index) in files" :key="index">
            {{ file.name }}
            <!--div @click="removeFile(index)" class="rounded-full flex justify-center w-5 h-5 items-center border border-black ml-4 cursor-pointer text-xs px-1">x</div-->
          </div>
          <div class="bg-red-500 max-w-min text-white px-4 rounded">vider</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      files: [],
    };
  },

  methods: {
    removeFile(index) {
      this.files.splice(index - 1, 1);
      this.$emit("input", this.files);
    },
    async previewFiles(e) {
      //Array.prototype.push.apply(this.files, e.target.files);
      //TODO: finir le travail;

      for (let index = 0; index < e.target.files.length; index++) {
        this.files.push(e.target.files[index]);
      }
      var names = "";
      this.files.forEach((element) => {
        names = names + "#" + element;
      });
      this.$emit("input", this.files);
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-70");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-70");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.uploads.files = event.dataTransfer.files;
      let e = {};
      e.target = event.dataTransfer;
      this.previewFiles(e); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>

<style lang="scss"></style>
